@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --color-primary: #e35205;
  --color-secondary: #ff8700;
}

body {
  color: #666;
  background-color: #f8f9fb;
  font-family: Montserrat;
  font-size: 14px;
}

.monospace {
  font-family: Calibri;
  font-weight: lighter;
}

h1 {
  font-size: 1.6em;
  font-weight: bold;
  line-height: 2em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1.1em;
}

a {
  color: var(--color-primary);
  text-decoration-style: dotted;
}

span.link, .link, .color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

a:hover {
  color: var(--color-secondary);
  text-decoration: none;
}

.modal-body {
  overflow-y: auto;
}

thead {
  background: #e9e9e9;
}

.text-small {
  font-size: .75rem;
}

.text-large {
  font-size: 1.25rem;
}

.card {
  color: #666;
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
  box-shadow: 0 .5rem 1rem #3c3c3c1a;
}

.card-body-compact {
  padding: .5rem 1.25rem;
}

.card-equal-height {
  min-height: calc(100% - 1.5em);
}

#user-menu {
  color: #444;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #00000020;
  border-radius: .25rem;
  width: 250px;
  min-height: 50px;
  margin-bottom: 1.5rem;
  padding: 10px;
  display: none;
  position: fixed;
  top: 57px;
  right: 16px;
  box-shadow: 0 .3rem .8rem #3c3c3c4d;
}

#menu-open {
  cursor: pointer;
  font-size: 25px;
  display: none;
  position: absolute;
  top: 8px;
  left: 20px;
}

#menu-close {
  cursor: pointer;
  color: #ccc;
  font-size: 25px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.rounded {
  border-radius: .25em;
}

.hoverable:hover, .hoverable:hover > * {
  color: var(--color-primary);
  background: #eee;
}

.selected {
  color: #fff;
  background-color: var(--color-primary);
}

#header {
  border-top: 5px solid var(--color-primary);
  color: #eee;
  z-index: 999;
  background-color: #333;
  min-height: 33px;
  padding: 10px 0 10px 10px;
  position: fixed;
  top: 0;
  height: 57px !important;
}

#header-user {
  cursor: pointer;
  font-size: 22px;
  position: absolute;
  top: 10px;
  right: 20px;
}

#header-logo {
  text-align: center;
}

.card-active {
  border-width: 2px;
  border-color: #a2a2a2;
  box-shadow: 0 .5rem 1.5rem #32323299 !important;
}

.card-title {
  background-color: #00000008;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding: 10px 20px 5px;
  font-size: 1.1em;
  font-weight: bold;
}

.card-fullscreen-btn {
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 12px;
}

.card-fullscreen {
  z-index: 999;
  width: calc(100% - 25px);
  position: fixed;
  inset: 67px -10px -10px 10px;
  max-height: unset !important;
}

.card-collapse-btn {
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 15px;
}

.scroll-y {
  overflow-y: scroll;
}

.scroll-x {
  overflow-x: scroll;
}

.card-title-expandable, .card-title-collapsable {
  cursor: pointer;
}

.card-footnote {
  padding: 0 20px 10px;
  font-size: .7em;
}

.prevent-select {
  -webkit-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
  pointer-events: auto;
}

body::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0000004d;
  border-radius: 5px;
  transition: color .2s;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0000004d;
  border-radius: 5px;
  transition: color .2s;
}

body::-webkit-scrollbar-track {
  background: #0000001a;
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background: #0000001a;
  border-radius: 0;
}

body::-webkit-scrollbar {
  width: .5em;
  height: .5em;
}

::-webkit-scrollbar {
  width: .5em;
  height: .5em;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar {
  -o-transition: all .5s ease;
  background-color: #fff;
  border-right: 1px solid #e0e0e0;
  width: 250px;
  height: 100%;
  transition: all .5s;
  position: fixed;
  top: 0;
  box-shadow: 0 .5rem 1rem #3c503c1a;
}

.main-content {
  width: calc(100% - 250px);
  margin-top: 75px;
  position: relative;
  left: 250px;
}

.auto-height {
  height: auto !important;
}

.navbar {
  cursor: pointer;
  margin-top: -21px;
  margin-bottom: 50px;
  padding: 0 !important;
}

.navbar > ul {
  width: 100%;
  padding: 0;
}

.nav-badge {
  position: absolute;
  right: 2em;
}

.menu-item {
  color: #666;
  background: #f9f9f9;
  width: 100%;
  padding: 10px;
  display: flex;
  line-height: 1 !important;
}

.menu-item:hover {
  color: #fff;
  background: #ccc;
}

.menu-item-icon {
  text-align: center;
  width: 40px;
  font-size: 1.1rem;
}

.menu-item-spacer {
  display: none;
  background: #f9f9f9 !important;
  width: 100% !important;
  min-height: 14px !important;
}

.menu-item-bottom {
  color: #eee;
  cursor: pointer;
  background: #666;
  width: 100%;
  padding: 10px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.checkbox-large {
  width: 1.4em;
  height: 1.4em;
  margin-left: .5rem !important;
}

.has-search .form-control-feedback {
  z-index: 2;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  line-height: 1.5rem;
  display: block;
  position: absolute;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.form-group-sm {
  margin-bottom: .6rem !important;
}

.table-search {
  margin-top: 5px;
}

.table-search > .fa-search {
  color: #aaa;
  line-height: 1.5em;
  position: absolute;
}

.table-search > input[type="text"] {
  padding-left: 2.35em;
}

.menu-current, .active, .menu-current:hover {
  background: var(--color-primary);
  color: #fff;
}

.user-card {
  cursor: pointer;
  color: #666;
  font-size: 1.1em;
}

.user-card:hover {
  color: var(--color-primary);
}

@media screen and (width <= 1200px) {
  .sidebar {
    z-index: 1000;
    position: fixed;
    left: -320px;
    box-shadow: 0 .5rem 1rem #3c3c3c80;
  }

  #menu-open {
    display: inline;
  }

  .main-content {
    width: 100% !important;
    left: 0 !important;
  }

  #header-logo {
    display: none;
  }
}

.sidebar-active {
  -o-transition: all .5s ease;
  transition: all .5s;
  left: 0;
}

.button-floating {
  background-color: var(--color-primary);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  font-size: 2em;
  position: fixed;
  bottom: 40px;
  right: 40px;
  box-shadow: 2px 2px 3px #999;
}

.button-floating:hover {
  color: #fff;
  background-color: var(--color-secondary);
}

.button-floating-icon {
  color: #fff;
  margin-top: 16px;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}

.table-hover-blue tr:hover td {
  background-color: var(--color-primary);
  color: #fff;
}
/*# sourceMappingURL=index.83852fb2.css.map */
