@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    /*    --color-primary: #0082ca;
    --color-secondary: #002e6d;*/
    --color-primary: #e35205;
    --color-secondary: #ff8700;
}

body {
    background-color: #f8f9fb;
    font-family: 'Montserrat';
    color: #666;
    font-size: 14px;
}

.monospace {
    font-family: 'Calibri';
    font-weight: lighter;
}

h1 {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 2em;
}

h2 {
    font-size: 1.4em;
}

h3 {
    font-size: 1.25em;
}

h4 {
    font-size: 1.1em;
}

a {
    color: var(--color-primary);
    text-decoration-style:dotted;
}

span.link {
    color: var(--color-primary);
}

.link {
    color: var(--color-primary);
}

/*.btn-primary {
    background-color: var(--color-primary);
    color: #fff;
    --bs-btn-border-color: var(--color-primary);
    --bs-btn-border-color: var(--color-primary);
    --bs-btn-hover-bg: var(--color-secondary);
    --bs-btn-hover-border-color: var(--color-secondary);
    --bs-btn-active-bg: var(--bs-secondary);
    --bs-btn-active-border-color: var(--bs-secondary);
}
.btn {
    --bs-btn-hover-bg: var(--color-secondary);
    --bs-btn-hover-border-color: var(--color-secondary);
}

.btn-selected {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}*/

.color-primary {
    color: var(--color-primary);
}

.color-secondary {
    color: var(--color-secondary);
}


a:hover {
    /* text-decoration-style: dotted; */
    /*font-weight:bold;*/
    text-decoration: none;
    color: var(--color-secondary);
}

.modal-content {
    /*max-height: 90vh;*/
}

.modal-body {
    overflow-y: auto;
}

thead {
    background: #e9e9e9;
}

.text-small {
    font-size: 0.75rem
}

.text-large {
    font-size: 1.25rem;
}


.card {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
    box-shadow: 0 0.5rem 1rem rgba(60, 60, 60, .1);
    color: #666;
}

.card-body-compact {
    padding: 0.5rem 1.25rem;
}

.card-equal-height {
    min-height: calc(100% - 1.5em);
}

#user-menu {
    margin-bottom: 1.5rem;
    padding: 10px;
    box-shadow: 0 0.3rem .8rem rgba(60, 60, 60, .3);
    color: #444;
    z-index: 9999;
    width: 250px;
    min-height: 50px;
    position: fixed;
    top: 57px;
    right: 16px;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    background-color: #fff;
    display: none;
}

#menu-open {
    font-size: 25px;
    position: absolute;
    left: 20px;
    top: 8px;
    cursor: pointer;
    display: none;
}

#menu-close {
    font-size: 25px;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    color:#ccc;
}

.rounded{
    border-radius:0.25em;
}

.hoverable:hover {
    background: #eee;
    color: var(--color-primary);
}

.hoverable:hover > * {
    background: #eee;
    color: var(--color-primary);
}


.selected {
    color: #fff;
    background-color: var(--color-primary);
}

#header {
    background-color: #333;
    border-top: 5px solid var(--color-primary);
    padding: 10px 0 10px 10px;
    color: #eee;
    position: fixed;
    top: 0;
    /*text-align: center;*/
    z-index: 999;
    min-height: 33px;
    height:57px!important;
}

#header-user {
    font-size: 22px;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
}

#header-logo {
    text-align: center;
}

.card-active {
    border-width: 2px;
    border-color: #a2a2a2;
    box-shadow: 0 0.5rem 1.5rem rgba(50, 50, 50, .6) !important;
}

.card-title {
    font-size: 1.1em;
    padding: 10px 20px 5px 20px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03)
}

.card-fullscreen-btn {
    position: absolute;
    right: 12px;
    top: 13px;
    cursor: pointer;
}

.card-fullscreen {
    top: 67px;
    bottom: -10px;
    right: -10px;
    width: calc(100% - 25px);
    left: 10px;
    position: fixed;
    z-index: 999;
    max-height: unset !important;
}

.card-collapse-btn {
    position: absolute;
    right: 15px;
    top: 13px;
    cursor: pointer;
}

.scroll-y {
    overflow-y: scroll;
}

.scroll-x {
    overflow-x: scroll;
}

.card-title-expandable, .card-title-collapsable {
    cursor: pointer;
}

/*use card-footer instead*/
.card-footnote {
    font-size: 0.7em;
    padding: 0px 20px 10px 20px;
}


.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.clickable {
    cursor: pointer;
    pointer-events: auto;
}


body::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, .3);
    transition: color .2s ease;
}

body::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .1);
    border-radius: 0;
}

body::-webkit-scrollbar, ::-webkit-scrollbar {
    width: .5em;
    height: .5em;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sidebar {
    position: fixed;
    top:0px;
    height: 100%;
    width: 250px;
    background-color: white;
    border-right: 1px solid #e0e0e0;
    box-shadow: 0 0.5rem 1rem rgba(60, 80, 60, .1);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.main-content {
    margin-top: 75px;
    width: calc(100% - 250px);
    left: 250px;
    position: relative;
}

.auto-height {
    height: auto !important;
}

.navbar {
    padding: 0!important;
    cursor: pointer;
    margin-top: -21px;
    margin-bottom: 50px;
}

    .navbar > ul {
        width: 100%;
        padding: 0;
    }

.nav-badge {
    position: absolute;
    right: 2em;
}

.menu-item {
    width: 100%;
    padding: 10px 10px;
    background: #f9f9f9;
    display: flex;
    line-height: 1 !important;
    color: #666;
}

    .menu-item:hover {
        background: #ccc;
        color: #fff;
    }

.menu-item-icon {
    width: 40px;
    text-align: center;
    font-size: 1.1rem
}

.menu-item-spacer {
    min-height: 14px !important;
    width: 100% !important;
    display: none;
    background: #f9f9f9 !important;
}

.menu-item-bottom {
    width: 100%;
    display: flex;
    padding: 10px 10px;
    background: #666;
    color: #eee;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}

.checkbox-large {
    margin-left: 0.5rem !important;
    height: 1.4em;
    width: 1.4em;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    line-height: 1.5rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.form-group-sm {
    margin-bottom: 0.6rem !important;
}

.table-search {
    margin-top: 5px;
}

    .table-search > .fa-search {
        line-height: 1.5em;
        position: absolute;
        color: #aaa;
    }

    .table-search > input[type="text"] {
        padding-left: 2.35em;
    }


.menu-current, .active,
.menu-current:hover {
    background: var(--color-primary);
    color: #fff;
}

.user-card {
    cursor: pointer;
    color: #666;
    font-size: 1.1em;
}

    .user-card:hover {
        color: var(--color-primary);
    }

@media screen and (max-width: 1200px) {
    .sidebar {
        position: fixed;
        left: -320px;
        box-shadow: 0 0.5rem 1rem rgba(60, 60, 60, .5);
        z-index: 1000;
    }

    #menu-open {
        display: inline;
    }

    .main-content {
        width: 100% !important;
        left: 0px !important;
    }

    #header-logo{
        display: none;
    }
}

.sidebar-active {
    left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.button-floating {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    font-size: 2em;
    cursor: pointer;
}

    .button-floating:hover {
        color: #fff;
        background-color: var(--color-secondary);
    }

.button-floating-icon {
    margin-top: 16px;
    color: #fff;
}


.opacity-25 {
    opacity: 0.25
}

.opacity-50 {
    opacity: 0.5
}

.opacity-75 {
    opacity: 0.75
}


.opacity-100 {
    opacity: 1;
}

.table-hover-blue tr:hover td {
    background-color: var(--color-primary);
    color: white;
}


